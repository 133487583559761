<template>
  <header class="header_main">
    <h1><router-link to="/">Violet</router-link></h1>
    <nav class="nav_bar">
      <div
        class="hamburger"
        :class="{ active: isSidebarActive }"
        @click="toggleSidebar"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul>
        <li>
          <router-link to="/commands">
            <span class="color_premium">
              <i class="fa-regular fa-gem"></i> Premium
            </span>
          </router-link>
        </li>
        <li><router-link to="/commands">Comandos</router-link></li>
        <li><router-link to="">Tutorial</router-link></li>
        <li><router-link to="/team">Equipe</router-link></li>
        <li><router-link to="/terms">Termos</router-link></li>
        <li v-if="isLoggedIn">
          <router-link to="/dashboard/daily">Daily</router-link>
        </li>
        <button class="discord_class">
          <a href="https://discord.gg/VKQHuas29A">
            <i class="fa-brands fa-discord"></i> Suporte
          </a>
        </button>
        <button class="discord_class2" @click="toggleAuth">
          <i
            :class="
              isLoggedIn ? 'fa-solid fa-door-closed' : 'fa-solid fa-door-open'
            "
          ></i>
          {{ isLoggedIn ? "Logout" : "Login" }}
        </button>
      </ul>
    </nav>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import eventBus from "@/services/eventBus";
import apiClient from "@/utils/api";

export default defineComponent({
  setup() {
    const router = useRouter();
    const isLoggedIn = ref(!!localStorage.getItem("user"));

    const toggleAuth = async () => {
      if (isLoggedIn.value) {
        localStorage.removeItem("user");
        isLoggedIn.value = false;
        eventBus.emit("auth-changed", false);
        router.push("/");
      } else {
        window.location.href = apiClient.getUri() + "@canary/discord/login";
      }
    };

    const updateAuthStatus = (status: boolean) => {
      isLoggedIn.value = status;
    };

    onMounted(() => {
      eventBus.on("auth-changed", updateAuthStatus);
    });

    return { isLoggedIn, toggleAuth };
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["toggle-sidebar"],
  methods: {
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
  },
});
</script>
